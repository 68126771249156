<template>
    <st-filters-pagination
        stateModule="beneficiaries/list"
        :header="$t('BENEFICIARIES.LIST.HEADER')"
        :perPage="10"
        :total="total"
        :filters="filters"
        :moreFilters="false"
        @change="doFilter"
    >
        <slot></slot>
    </st-filters-pagination>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { FilterSchema } from '@/shared/form/filter-schema';
import { BeneficiaryModel } from "@/modules/beneficiaries/models/beneficiary-model";

const { fields } = BeneficiaryModel;

const filterSchema = new FilterSchema([
    fields.identifier,
    fields.email,
    fields.locality_id,
]);

export default {
    name: 'BeneficiariesFilter',
    data() {
        return {
            filters: filterSchema.fields,
        }
    },
    computed: {
        ...mapGetters({
            total: 'beneficiaries/list/total',
        }),
    },
    methods: {
        ...mapActions({
            getBeneficiaries: 'beneficiaries/list/getBeneficiaries',
        }),
        ...mapMutations({
            setParams: 'beneficiaries/list/setParams',
        }),
        doFilter(params) {
            this.setParams(params);
            this.getBeneficiaries();
        },
        updateOptions() {
            this.$refs['filters'].updateOptions();
        },
    },
}
</script>
