<template>
    <div class="form-group" id="st_beneficiary_section">
        <BeneficiaryFormTypes
            ref="beneficiary"
            :viewMode="viewMode"
            :editMode="editMode"
            :item="selectedEntity"
            @submit="submit"
            @cancel="cancel"
        >
            <template #buttons="{ submit, cancel }">
                <div class="d-flex justify-content-between">
                    <st-button size="large" variant="link" :callback="cancel">
                        {{ $t('APPLICATION.MODALS.CANCEL') }}
                    </st-button>
                    <st-button size="large" :callback="submit">
                          {{ submitButtonText }}
                    </st-button>
                </div>
            </template>
        </BeneficiaryFormTypes>
    </div>
</template>

<script>
import BeneficiaryFormTypes from "./BeneficiaryFormTypes";
import { mapActions, mapGetters } from "vuex";
import { generateRandomId } from '@/core/helpers/globalMethods';

export default {
    name: "BeneficiaryForm",
    props: {
        viewMode: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            required: false
        },
    },
    data() {
        return {
            selectedEntity: null
        };
    },
    components: {
        BeneficiaryFormTypes,
    },
    computed: {
        ...mapGetters({
            record: "beneficiaries/form/record",
        }),
        submitButtonText() {
            return this.item ? this.$t('APPLICATION.MODALS.SAVE') : this.$t('APPLICATION.MODALS.ADD');
        },
    },
    methods: {
        ...mapActions({
            getBeneficiaries: 'beneficiaries/form/doFindBeneficiaries',
            doAddBeneficiary: "beneficiaries/form/addBeneficiary",
            doUpdateBeneficiary: "beneficiaries/form/updateBeneficiary",
        }),
        onModelChange(model) {
            this.$emit("change", model);
        },
        trimBeneficiaryValues(obj) {
            // Create a new object where each value is trimmed
            const trimmedObj = {};

            for (const key in obj) {
                if (obj.hasOwnProperty(key) && typeof obj[key] === 'string') {
                    trimmedObj[key] = obj[key] ? obj[key].trim() : obj[key]; // Trim the value if it's a string
                } else {
                    trimmedObj[key] = obj[key]; // Keep non-string values as they are
                }
            }

            return trimmedObj;
        },        
        async validateData() {
            const fieldRefs = this.$refs.beneficiary.$refs;
            const selectedEntityKey = Object.keys(fieldRefs).find(
                (key) => !!fieldRefs[key]
            );
            const selectedEntity = fieldRefs[selectedEntityKey];
            selectedEntity.model = this.trimBeneficiaryValues(selectedEntity.model);   

            let status = await selectedEntity.fv.validate();
            let details = this.fillBeneficiaryModel(selectedEntity);

            const beneficiary_id = this.item?.beneficiary_id || generateRandomId();
            const beneficiaryDetails = { ...selectedEntity.model, ...details, beneficiary_id };
            if (status === 'Valid') {
                 if (beneficiaryDetails?.user_type_name === 'citizen' && beneficiaryDetails?.personal_numeric_code) {
                    if (this.item?.personal_numeric_code?.trim() !== beneficiaryDetails?.personal_numeric_code.trim()) {
                        beneficiaryDetails.personal_numeric_code = beneficiaryDetails?.personal_numeric_code?.trim();
                        const params = {
                            query: `personal_numeric_code eq \'${beneficiaryDetails?.personal_numeric_code?.trim()}\' and user_type_name eq \'${beneficiaryDetails?.user_type_name}\'`
                        }
                        const beneficiaries = await this.getBeneficiaries({ params });
                        if (beneficiaries?.data?.length) {
                            status = 'Invalid';
                            this.$alert({
                                type: 'warning',
                                text: this.$t('APPLICATION.NOTIFIERS.DUPLICATE_BENEFICIARY_PNC', {pnc: beneficiaryDetails.personal_numeric_code}),
                                showConfirmButton: false,
                                showCancelButton: true,
                                cancelButtonText: this.$t('GENERAL.BUTTON.UPDATE'),
                            });
                            return { status };                             
                        }
                    }
                }
                if (beneficiaryDetails?.user_type_name === 'legal_entity' && beneficiaryDetails?.unique_registration_code?.trim()) {
                    if (this.item?.unique_registration_code?.trim() !== beneficiaryDetails?.unique_registration_code?.trim()) {
                        beneficiaryDetails.unique_registration_code = beneficiaryDetails?.unique_registration_code?.trim();
                        let uniqueRegistrationCode = beneficiaryDetails?.unique_registration_code?.toLowerCase();
                        if (uniqueRegistrationCode?.startsWith('ro')) {
                            uniqueRegistrationCode = uniqueRegistrationCode.substring(2);
                        }
                        const params = {
                            query: `unique_registration_code like \'${uniqueRegistrationCode}\' and user_type_name eq \'${beneficiaryDetails?.user_type_name}\'`
                        }
                        const beneficiaries = await this.getBeneficiaries({ params });
                        if (beneficiaries?.data?.length) {
                            status = 'Invalid';
                            this.$alert({
                                type: 'warning',
                                    text: this.$t('APPLICATION.NOTIFIERS.DUPLICATE_BENEFICIARY_REG_CODE', {unique_registration_code: beneficiaryDetails.unique_registration_code}),
                                    showConfirmButton: false,
                                    showCancelButton: true,
                                    cancelButtonText: this.$t('GENERAL.BUTTON.UPDATE'),
                            });
                            return { status };                            
                        }
                    }
                }            
            }

            return {
                status,
                beneficiaryDetails,
            };
        },
        fillBeneficiaryModel(entity) {
            if (entity.model.user_type_name === "foreigner") {
                return {};
            }

            let details = {
                locality: entity.fields.locality_id.options.find((el) => el.value === entity.model.locality_id)?.text || "",
                county: entity.fields.county_id.options.find((el) => el.value === entity.model.county_id)?.text || "",
                village: entity.fields.village_id?.options.find((el) => el.value === entity.model.village_id)?.text || "",
            };

            return details;
        },
        async submit(data) {
            let beneficiary = await this.validateData();
            if (beneficiary.status === 'Valid') {
                if (this.item) {
                    beneficiary.index = this.item.index;
                }
                if (!data) {
                    beneficiary.beneficiaryDetails = await this.doAddBeneficiary(beneficiary.beneficiaryDetails);
                } else {
                    beneficiary.beneficiaryDetails = await this.doUpdateBeneficiary(beneficiary.beneficiaryDetails);
                }

                this.$emit("submitForm", beneficiary);
            }
        },
        cancel() {
            this.$emit("cancelForm");
        }
    },
    created() {
        this.selectedEntity = this.item || null;
        this.editMode = this.item ? true : false;
    },
};
</script>
